<template>
  <div class="info">
    <p class="tips">我们将发送验证码到您的手机</p>
    <van-field
      v-model="tel"
      type="tel"
      label="新手机号"
      placeholder="请输入新手机号"
      maxlength="11"
    />
    <van-field
      v-model="sms"
      center
      clearable
      label="短信验证码"
      placeholder="请输入短信验证码"
    >
      <template #button>
        <p class="get_code" @click="getCode" v-if="codeShow">获取验证码</p>
        <p v-else class="count_down">{{ codeCountDown }}s后重新获取</p>
      </template>
    </van-field>
    <p class="Error" v-show="error">
      <svg-icon icon-class="login_note" />{{ error }}
    </p>
    <p class="Error" v-show="!error"></p>
    <div class="button">
      <van-button @click="next" block round color="#009A4D">确认</van-button>
    </div>
  </div>
</template>

<script>
import { getToken, setToken, removeToken } from "@/utils/auth";
export default {
  name: "mine",
  data() {
    return {
      tel: "",
      error: "",
      sms: "",
      codeCountDown: 59,
      codeShow: true, //验证码显示
    };
  },
  watch: {},

  mounted() {},

  methods: {
    next() {
      if (/^(?:(?:\+|00)86)?1\d{10}$/.test(this.tel)) {
        this.error = "";
        this.verificationCAPTCHA();
      } else {
        this.error = "请输入正确手机号";
      }
    },
    //获取验证码
    async getCode() {
      if (this.tel === "" || !/^(?:(?:\+|00)86)?1\d{10}$/.test(this.tel)) {
        this.error = "请输入正确手机号";
        return;
      }
      this.error = "";
      await this.$API.login
        .sendCAPTCHA({
          mobile: this.tel,
        })
        .then((res) => {
          this.Toast.success("发送成功");
          this.codeShow = false;
          let t = setInterval(() => {
            this.codeCountDown--;
            if (this.codeCountDown <= 0) {
              this.codeShow = true;
              clearInterval(t);
              this.codeCountDown = 59;
            }
          }, 1000);
        })
        .catch(() => {
          this.Toast.fail("发送失败");
        });
    },
    //校验验证码
    async verificationCAPTCHA() {
      await this.$API.login
        .verificationCAPTCHA({
          mobile: this.tel,
          captcha: this.sms,
        })
        .then((res) => {
          if (res.data.data.result) {
            this.error = "";
            this.changeTel();
          } else {
            this.error = "验证码错误";
          }
        });
    },
    async changeTel() {
      await this.$API.user
        .updateUserName({
          object: {
            code: this.sms,
            userName: this.tel,
          },
        })
        .then((res) => {
          removeToken();
          this.$store.commit("user/LoginOut", "");
          this.$router.replace("/login");
          this.Toast.success("修改成功 要重新登录");
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.info {
  background: #f5f5f5;
  position: absolute;
  top: 46px;

  bottom: 0;
  left: 0;
  right: 0;
  max-width: 500px;
  margin: 0 auto;
  .tips {
    color: #666666;
    padding: 12px 15px;
    line-height: 14px;
  }

  .Error {
    text-indent: 10px;
    .svg-icon {
      margin-right: 6px;
    }
    height: 18px;
    font-size: 12px;
    color: #f61423;
    margin-top: 8px;
  }
  .get_code {
    color: #009a4d;
  }
  .button {
    padding: 0 15px;
    ::v-deep .van-button {
      margin-top: 40px;
    }
  }
}
</style>